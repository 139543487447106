import { post, get, put } from '@/http/http'

// 用户登陆
export const userLogin = p => post('v1/sysuser/userlogin', p)

// 用户查询(总系统)
export const userTot = p => post('v1/sysuser/getuser_tot', p)

// 用户查询(子系统)
export const userSub = p => post('v1/sysuser/getuser_sub', p)

// 查询行政区划
export const getDivision = p => post('v1/sysuser/getdivision', p)

// 查询角色
export const getRole = p => post('v1/sysuser/getroleid', p)

// 用户添加
export const addUser = p => post('v1/sysuser/addgetuser', p)

// 用户停用
export const userEnable = p => post('v1/sysuser/userenable', p)

// IP锁定
export const userIplock = p => post('v1/sysuser/useriplock', p)

// 异地状态
export const enableStop = p => post('v1/sysuser/enablestop', p)

// 密码重置
export const resetUserInfo = p => post('v1/sysuser/resetuserinfo', p)

// 通知查询
export const getnoticeSet = p => post('v1/sysuser/getnoticeset', p)

// 通知设置
export const noticeSetup = p => post('v1/sysuser/noticesetup', p)


// 数据查询
export const getData = p => post('v1/backupdata/getbackupdata', p)

// 区域查询
export const getArea = p => post('v1/backupdata/getarea', p)

// 日志查询
export const getLog = p => post('v1/logging/getlogging', p)
