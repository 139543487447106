<template>
  <div>
    <el-breadcrumb separator="">
      <el-breadcrumb-item class="sptor">数据管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="setting_main_bg">
      <div class="unit_cont_box">
        <!-- <div class="iconfont iconjia settingAdd" @click="handleUserAdd()"><span>新增</span></div> -->
        <div class="flexAC ut_top">
          <el-input v-model="bapname"  placeholder="请输入文件名称" class="contInput" clearable></el-input>
          <el-select v-model="baptype" placeholder="区域" class="utG setSe" clearable  @change="handleArea">
            <el-option
              v-for="item in baptypeList"
              :key="item.index"
              :label="item.area"
              :value="item.id">
            </el-option>
          </el-select>
          <el-button class="settingSearch" @click="handleSearch()">搜索</el-button>
          <el-button class="settingReset" @click="handleReset()">重置</el-button>
        </div>
      </div>
      <div class="user_table">
        <el-table
          stripe
          :data="dataList"
          style="width: 100%">
          <el-table-column
            label="序号"
            type="index">
          </el-table-column>
          <el-table-column
            label="区域"
            prop="area">
          </el-table-column>
          <el-table-column
            label="文件名"
            prop="bapname">
          </el-table-column>
          <el-table-column
            label="文件类型"
            prop="baptype">
          </el-table-column>
          <el-table-column
            label="备份时间"
            prop="creatime">
          </el-table-column>
          <el-table-column
            label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="handleDownload(scope.row)">下载</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        @current-change="PageChange"
        :current-page="page"
        :page-sizes="[size]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        class="settingPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getData, getArea } from '@/api/user.js'
export default {
  data () {
    return {
      bapname: '',
      page: 1,
      size: 10,
      total: 0,
      dataList: [],
      baptype: '',
      baptypeList: [],
      area: '',
      areaList: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      // 区域查询
      getArea({}).then(res => {
        if (res.status === '1') {
          if (res.data.data !== undefined || res.data.data !== null) {
            this.baptypeList = res.data
          } else {
            this.baptypeList = []
          }
        } else {
          this.baptypeList = []
        }
      })
      
      const parmas = {
        bapname: this.bapname,
        baptype: this.baptype,
        page_index: this.page,
        page_size: this.size
      }
      getData(parmas).then(res => {
        if (res.status === '1') {
          if (res.data.data !== undefined || res.data.data !== null) {
            this.dataList = res.data.data
            this.total = res.data.recordcount
          } else {
            this.dataList = []
            this.total = 0
          }
        } else {
          this.dataList = []
          this.total = 0
        }
      })
    },
    // 区域选择
    handleArea (val) {
      this.baptype = val
    },
    handleDownload (row) {
      window.open(row.bapfile, '_blank')
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    handleSearch () {
      this.page = 1
      this.init()
    },
    handleReset () {
      this.page = 1
      this.bapname = ''
      this.baptype = ''
      this.init()
    },
  }
}
</script>

<style scoped>
</style>
